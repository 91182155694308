











import { Component, Vue } from 'vue-property-decorator';
import FormCanzone from './FormCanzone.vue';
  
@Component({
    components: {
        FormCanzone
    }
})
export default class CreaCanzone extends Vue {
    // Initial data can be declared as instance properties
    pageTitle: string = 'Crea Canzone';
}
